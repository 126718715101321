import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';

@Injectable()
export class BasicService{
    baseUrl:string = '';
    
    constructor(protected http: HttpClient){

    }

    getAll():Observable<any[]>{
        const url = `${this.baseUrl}/all`;
        return this.http.get<{rows: any, status: string}>(url).pipe(
            map((response)=>{
                return response.rows;
            })
        )
    }

    getById(itemId: number):Observable<any>{
        const url = `${this.baseUrl}/${itemId}`;
        return this.http.get<{rows: any, status: string}>(url).pipe(
            map((response)=>{
                return response.rows[0];
            })
        )
    }

    getPage(queryParams: QueryParamsModel):Observable<QueryResultsModel>{
        const url = `${this.baseUrl}/page`;
        console.log(url);
        return this.http.post<QueryResultsModel>(url, queryParams);
    }

    getByEntityId(entityId: number|string){
        const queryParams: QueryParamsModel = {
            filter: {entity_id: entityId},
            pageNumber: 0,
            pageSize: 0,
            sortField: 'id',
            sortOrder: 'desc',
            option: ''
        };
        return this.getPage(queryParams);
    }

    add(item:any):Observable<number>{
        // Display the key/value pairs
        console.log("will go with add");
        // console.dir(item);
        console.dir(Array.from(item.entries()));
        // for (var pair of item.entries()) {
        //     console.log(pair[0]+ ', ' + pair[1]); 
        // }
        // console.log("formdata printed");
        // const options = {
        //     headers: new HttpHeaders({
        //         'Content-Type': undefined,
        //     })
        // }
        console.log("url: ", this.baseUrl);
        return this.http.post<any>(this.baseUrl, item).pipe(
            tap((response)=>{console.log("response: ");console.dir(response)}),
            // map((response)=> response && response.status=='error' && response.message!=undefined ? response:  (response!=null ? response.id || response.ids: response))
        );
    }

    edit(item:any):Observable<string>{
        // console.log("edit input: "+JSON.stringify(item));
        return this.http.put<any>(`${this.baseUrl}/${item.id || item.get('id')}`, item).pipe(
            tap((response:any)=>console.dir(response)),
            map((response)=>response.status)
        );
    }

    delete(item:any):Observable<string>{
        console.log(`${this.baseUrl}/${item.id}`);
        return this.http.delete<any>(`${this.baseUrl}/${item.id || item.get('id')}`).pipe(
            map((response)=>response.status),
            tap((resp)=>{
                console.log(`delete with status ${resp}`)
            })
        );
    }
}